import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { storeUserEnter } from "../utils/firebase.util";

function Login({ loading, setLoading, setMember, setVerified }) {
  const [formControlrs, setFormControlrs] = useState({
    email: {
      key: "email",
      label: "To Watch The Video Please Enter Your Email.",
      type: "email",
      placeHolder: "Enter Your Email",
      errorMsg: "Please enter a valid email.",
      errorMsgVisability: false,
      value: "",
    },

  });

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let isError = false;
      for (let [key, obj] of Object.entries(formControlrs)) {
        if (
          obj.value === "" ||
          (obj.type === "email" && !isValidEmail(obj.value))
        ) {
          obj.errorMsgVisability = true;
          isError = true;
        } else {
          obj.errorMsgVisability = false;
        }
        setFormControlrs({ ...formControlrs, [key]: obj });
      }

      if (!isError) {
        const data = Object.entries(formControlrs)
          .map(([, obj]) => obj)
          .reduce((a, b) => {
            a[b.key] = b.value;
            return a;
          }, {});
        storeUserEnter(data);
        setMember(data.email);
        setVerified(true);
      }
      setLoading(false);
    } catch (error) {
      console.log("onSubmit Error", error);
    }
  };
  function isValidEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  return (
    <form onSubmit={submitHandler}>
      {Object.entries(formControlrs).map(([key, input]) => (
        <div key={key}>
          <InputLabel>{input.label}</InputLabel>
          <FormControl fullWidth margin="normal">
            <OutlinedInput
              disabled={loading}
              placeholder={input.placeHolder}
              required
              value={input.value}
              onChange={(e) =>
                setFormControlrs({
                  ...formControlrs,
                  [key]: { ...formControlrs[key], value: e.target.value },
                })
              }
              variant="outlined"
              name={key}
              type={input.type}
              autoComplete="off"
              error={input.errorMsgVisability}
            />
            <FormHelperText>
              {input.errorMsgVisability && input.errorMsg}
            </FormHelperText>
          </FormControl>
        </div>
      ))}
      <div className="submit-button-container">
        {" "}
        <Button variant="contained" type="submit">
          Sign In
        </Button>
      </div>
    </form>
  );
}

export default Login;
