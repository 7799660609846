import React from "react";
import { getLocalImageByName } from "../utils";
import { addAction } from "../utils/firebase.util";
export default function ASide({
  sequence,
  changeTimeTo,
  currentTime,
  className,
  user,
}) {
  return (
    <aside className={`aside-container ${className}`}>
      <h3>{sequence.title}</h3>
      <div className="section-container">
        {sequence.data.map(({ title, start, end, thumbnail }) => (
          <div
            className={`section ${
              currentTime >= start && currentTime < end ? "active" : ""
            }`}
            key={title}
            onClick={() => {
              if (user) {
                addAction(user, {
                  action: "Select",
                  sectionTitle: title,
                  videoTime: currentTime,
                  time: new Date(),
                });
              }
              changeTimeTo(start);
            }}
          >
            <div className="image-container">
              {thumbnail && (
                <img
                  src={
                    thumbnail.startsWith("http")
                      ? thumbnail
                      : getLocalImageByName(thumbnail)
                  }
                  alt={title}
                />
              )}
              <span>
                {parseInt(Math.abs((end - start) / 60))}:
                {String(parseInt(Math.abs((end - start) % 60))).padStart(
                  2,
                  "0"
                )}
              </span>
            </div>
            <p> {title} </p>
          </div>
        ))}
      </div>
    </aside>
  );
}
