import { db } from "../firebase-config";
import metaData from "../videoMetdata.json";
import "firebase/database";

const getUserData = async (email) => {
  const userDocRef = await db
    .collection(metaData.collectionName)
    .doc(email)
    .get();
  let userDocData = userDocRef.data();
  return userDocData;
};

const storeUserEnter = async (data) => {
  let userDocData = await getUserData(data.email);
  if (!userDocData) userDocData = { entrieTimes: [], ...data };
  userDocData.entrieTimes = [...userDocData.entrieTimes, new Date()];
  update(data.email, userDocData);
};

const update = (email, obj = {}) => {
  db.collection(metaData.collectionName).doc(email).set(obj);
};

const addAction = async (email, action) => {
  let userDocData = await getUserData(email);
  if (!userDocData?.actions) userDocData.actions = [];
  userDocData.actions = [...userDocData.actions, action];
  update(email, userDocData);
};

const getAllUsers = async () => {
  const snapshot = await db.collection(metaData.collectionName).get();
  return snapshot;
};

export { getAllUsers, storeUserEnter, getUserData, addAction };
