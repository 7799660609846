import React, {
  createRef,
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import {
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay10,
  VolumeOff,
  VolumeUp,
  CloseSharp,
  ListAlt,
  Help,
  ArrowForwardIos,
} from "@material-ui/icons";
import { ClickAwayListener } from "@material-ui/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ASide from "./ASide";
import { getLocalImageByName } from "../utils";
import { addAction } from "../utils/firebase.util";
const InterActiveVideo = forwardRef(
  ({ poster, video, metaData, member: user }, ref) => {
    const videoRef = createRef();
    const [interactiveMetaData, setInteractiveMetaData] = useState(
      JSON.parse(JSON.stringify(metaData))
    );
    const [showPoster, setShowPoster] = useState(true);
    const [previewImage, setPreivewImage] = useState({ view: false, src: "" });
    const [playing, setPlaying] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [muted, setMuted] = useState(false);
    const [time, setTime] = useState(0);
    const [runningInteraction, setRunningInteraction] = useState(false);
    const fullScreenHandler = useFullScreenHandle();
    const [animationClassName, setAnimationClassName] = useState("");

    useImperativeHandle(ref, () => ({
      spaceAction() {
        toggleVideo();
      },
    }));

    const init = () => {};
    const updateSeenCount = (currentAction) => {
      currentAction.seenCount = currentAction.seenCount
        ? currentAction.seenCount + 1
        : 1;

      setRunningInteraction({ ...runningInteraction });
    };
    const handleSequenceAction = (action) => {
      updateSeenCount(action);
      switch (action.type) {
        case "prefiewImage":
          viewImageLayer(action);
          break;
        default:
          changeTimeTo(action.time);
      }
    };

    const viewImageLayer = (currentAction) => {
      setPreivewImage({
        view: true,
        src: currentAction.img_src,
      });
    };

    const changeTimeTo = (time) => {
      videoRef.current.currentTime = time;
      setTime(time);
      setRunningInteraction(false);
      playVideo();
      if (showPoster) {
        setShowPoster(false);
      }
      if (isDrawerOpen) {
        setDrawerOpen(false);
      }
    };

    const timeChangeHandler = () => {
      const currentTime = videoRef.current?.currentTime;
      setTime(currentTime);
      const interaction = interactiveMetaData.interActions.find((section) =>
        section.start.includes(Math.round(currentTime))
      );
      if (interaction) {
        startAction(interaction);
      }
    };

    const startAction = (interaction) => {
      if (interaction.redirectTo) {
        changeTimeTo(interaction.redirectTo);
      }
      if (interaction.onAllActionsClicked) {
        const clickedActionsLength = interaction.actions.filter(
          (action) => action.seenCount >= 1
        ).length;
        if (clickedActionsLength === interaction.actions.length) {
          changeTimeTo(interaction.onAllActionsClicked.redirectTo);
          return;
        }
      }
      pauseVideo();
      setRunningInteraction(interaction);
    };

    const playVideo = () => {
      videoRef.current?.play();
    };

    const pauseVideo = () => {
      videoRef.current?.pause();
    };

    const toggleVideo = () => {
      let action = "Play";
      if (playing) {
        pauseVideo();
        action = "Pause";
      } else {
        playVideo();
      }
      if (user) {
        addAction(user, { action, videoTime: time, time: new Date() });
      }

      setAnimationClassName("animation");

      if (showPoster) {
        setShowPoster(false);
      }
    };

    const toggleFullScreen = () => {
      if (fullScreenHandler.active) {
        fullScreenHandler.exit();
      } else {
        fullScreenHandler.enter();
      }
    };

    const toggleSound = () => {
      setMuted(!muted);
    };

    const backword = (numOfSeconds) => {
      const targetTime = time - numOfSeconds;
      const activeSection = interactiveMetaData.sequence.data.find(
        (section) => time >= section.start && time < section.end
      );
      if (activeSection) {
        changeTimeTo(activeSection.start || targetTime || 0);

        if (showPoster) {
          setShowPoster(false);
        }
      }
    };

    useEffect(() => {
      videoRef.current.muted = muted;
    }, [muted, videoRef]);

    const toggleDrawer = (val) => (event) => {
      event.stopPropagation();
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(val);
    };

    return (
      <FullScreen
        handle={fullScreenHandler}
        className={`interactive-video-container wow fadeInUp`}
      >
        <article className={`video-container ${isDrawerOpen ? "active" : ""}`}>
          {showPoster && (
            <div className="poster">
              <img onClick={toggleVideo} src={poster} alt="video poster" />
            </div>
          )}
          <video
            ref={videoRef}
            onLoadedData={init}
            onTimeUpdate={timeChangeHandler}
            onPlaying={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onEnded={() =>
              setInteractiveMetaData(JSON.parse(JSON.stringify(metaData)))
            }
            playsInline
          >
            <source id="mp4" src={video} type="video/mp4" />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>

          <div
            onClick={toggleVideo}
            onDoubleClick={toggleFullScreen}
            className={`playing-control-container`}
          >
            <div
              className={animationClassName}
              onAnimationEnd={() => setAnimationClassName("")}
            >
              {!playing ? <Pause /> : <PlayArrow />}
            </div>
          </div>

          <footer className="footer">
            <div>
              <button onClick={() => backword(10)}>
                <Replay10 />
              </button>
              <button onClick={toggleVideo}>
                {playing ? <Pause /> : <PlayArrow />}
              </button>

              <button onClick={toggleSound}>
                {muted ? <VolumeOff /> : <VolumeUp />}
              </button>
              <button onClick={toggleDrawer(!isDrawerOpen)}>
                <p>Library</p> <ListAlt />
              </button>
              <button onClick={toggleFullScreen}>
                {fullScreenHandler.active ? <FullscreenExit /> : <Fullscreen />}
              </button>
            </div>
          </footer>
        </article>

        {runningInteraction && (
          <article
            className={`${
              runningInteraction.ContainerClassName || "interactions-container"
            }`}
          >
            <article
              className={`${
                runningInteraction.wrapperClassName || "interaction-wrapper"
              }`}
            >
              {runningInteraction.title && (
                <div
                  className={`${
                    runningInteraction.titleContainerClassName ||
                    "question-container"
                  }`}
                >
                  <p>
                    {runningInteraction.titleIconVisability && <Help />}
                    {runningInteraction.title}
                  </p>
                </div>
              )}
              <div
                className={`${
                  runningInteraction.actionsContainerClassName ||
                  "actions-container"
                }`}
              >
                {runningInteraction.actions.map((action) => (
                  <p
                    key={action.text}
                    className={`action ${
                      action.seenCount >= action.maxSeenCount ? "disabled" : ""
                    }`}
                    onClick={() => {
                      if (
                        !action.seenCount ||
                        !action.maxSeenCount ||
                        action.seenCount < action.maxSeenCount
                      ) {
                        handleSequenceAction(action);
                      }
                    }}
                  >
                    <ArrowForwardIos /> {action.text}
                  </p>
                ))}
              </div>
              {runningInteraction.skipOption && (
                <footer>
                  <p
                    onClick={() => {
                      if (runningInteraction.skipOption.continue) {
                        changeTimeTo(time + 1);
                      } else {
                        changeTimeTo(runningInteraction.skipOption.targetTime);
                      }
                    }}
                  >
                    {runningInteraction.skipOption.text}
                  </p>
                </footer>
              )}
            </article>
          </article>
        )}

        {previewImage.view && (
          <article className="image-preview">
            <div
              onClick={() => {
                console.log("+");
                if (runningInteraction.onAllImageView.call) {
                  const previewImageActions = runningInteraction.actions.filter(
                    (action) => action.type === "prefiewImage"
                  );
                  const disableAction = previewImageActions.filter(
                    (action) => action.seenCount >= action.maxSeenCount
                  );
                  const allImageViewed =
                    previewImageActions.length === disableAction.length;
                  setPreivewImage({ view: false, src: "" });
                  if (allImageViewed) {
                    changeTimeTo(runningInteraction.onAllImageView.redirectTo);
                  }
                } else {
                  setPreivewImage({ view: false, src: "" });
                }
              }}
            >
              <CloseSharp />
            </div>
            <img
              src={
                previewImage.src.startsWith("http")
                  ? previewImage.src
                  : getLocalImageByName(previewImage.src)
              }
              alt={"img"}
            />
          </article>
        )}

        <ClickAwayListener onClickAway={toggleDrawer(false)}>
          <div>
            <ASide
              className={isDrawerOpen ? "active" : ""}
              sequence={interactiveMetaData.sequence}
              changeTimeTo={changeTimeTo}
              currentTime={time}
              user={user}
            />
          </div>
        </ClickAwayListener>
      </FullScreen>
    );
  }
);

export default InterActiveVideo;
