import React, { useState, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import logo from "../assets/logo.png";
import { Helmet } from "react-helmet";
import "firebase/firestore";
import fill from "../assets/video.mp4";
import InterActiveVideo from "../Components/InterActiveVideo";
import Login from "../Components/Login";
import poster from "../assets/videoPoster.png";
import videoBackground from "../assets/videoBackground.png";
import metaData from "../videoMetdata.json";

function HomePage() {
  const [verified, setVerified] = useState(!metaData.private);
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState();
  const videoRef = useRef(null);

  return (
    <div
      className="App"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.code === "Space" && videoRef.current) {
          videoRef.current.spaceAction();
        }
      }}
      style={{
        backgroundImage: `url(${verified ? videoBackground : poster})`,
      }}
    >
      <img className="logo wow fadeInRight" src={logo} alt="logo" />
      {(verified && (
        <section className="home-video-container">
          <Helmet>
            <title>{metaData.title}</title>
          </Helmet>
          <h3 className="wow fadeInDown">{metaData.title}</h3>
          <InterActiveVideo
            ref={videoRef}
            {...{ video: fill, poster: poster, metaData, member }}
          />
        </section>
      )) || (
        <div className="select-lang-page">
          {(loading && <CircularProgress />) || (
            <>
              <Helmet>
                <title>{metaData.title}</title>
              </Helmet>
              <div className="form">
                <h1 className="big-title ">{metaData.title}</h1>
                <div className="language-selector">
                  {!verified && (
                    <Login
                      {...{ loading, setLoading, setMember, setVerified }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default HomePage;
