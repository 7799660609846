import HomePage from "./Pages/Home";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(41, 41, 41, 0.9)",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
            <HomePage />

    </ThemeProvider>
  );
}

export default App;
